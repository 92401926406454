import React from "react";
import TopHubCard from "./TopHubImage/TopHubCard";
import ComingSoon from "../Images/comingsoon.svg";
import styles from "./TopHubImage/TopHubCard.module.css";
const HubPage = () => {
  return (
    <div className={styles.Gradient}>
      <TopHubCard />
      <div className={styles.comingSoon}>
        <img src={ComingSoon} alt="" />
      </div>
    </div>
  );
};

export default HubPage;
