import React from 'react'
import HomeTopCard from './HomeTopCard/HomeTopCard'
import NexFluentTransform from './NexFluentTransform/NexFluentTransform'
import FlexCardContainer from './NexFluentTransform/FlexCardContainer'
import DecisionCount from './DecisionCount/DecisionCount'
import IndustryWeServe from './IndustryWeServe/IndustryWeServe'
import DataFuture from './IndustryWeServe/DataFuture'
import ForInvestor from './ForInvestor/ForInvestor'

const Home = () => {
  return (
    <div>
      <HomeTopCard/>
      <NexFluentTransform/>
      <FlexCardContainer/>
      <DecisionCount/>
      <IndustryWeServe/>
      <DataFuture/>
      <ForInvestor/>
    </div>
  )
}

export default Home
