import React from 'react'
import styles from './WhyNexImg.module.css';

const LastText = () => {
    return (
        <div className={styles.topCard}>
            <div className={styles.FlextopCard}>
                <div className={styles.divH2}>
                    <h2>data + decision</h2>
                </div>
            </div>

            <div className={styles.FlextopCardfluency}>
                <div className={styles.divH2fluency}>
                   <h2>fluency</h2>
                </div>
            </div>

            <div className={styles.LastCard}>
            <a style={{color:"white",textDecoration:"none"}} href='mailto:info@nexfluent.com'><button className={styles.Button}>Get Started Now</button></a>
            </div>
        </div>
    )
}

export default LastText
