import React from "react";
import styles from "./NexFluentTransform.module.css";

const data = [
  {
    text: "Build a Unified Data Language",
    para: "Define, agree on, and manage data terms across the organization.",
  },
  {
    text: "AI-Powered Insights",
    para: "Leverage AI and Machine Learning to uncover hidden connections and trends within your data",
  },
  {
    text: "Seamless Collaboration",
    para: "Foster collaboration and data sharing through a centralized data hub.",
  },
  {
    text: "Future-Proof Your Data Foundation",
    para: "Ensure consistent data definitions for continued growth and scalability.",
  },
];

const FlexCardContainer = () => {
  return (
    <div className={styles.topCardFlex}>
      <div className={styles.FlexBox}>
        {data.map((el, index) => (
          <div key={index}>
            <br />
            <div className={styles.DivH4}>
              <h4 className="font-size-cardheader">{el.text}</h4>
            </div>
            <div className={styles.DivP}>
              <p className="font-size-cardsubheader">{el.para}</p>
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />
      <br />
      <a style={{ color: "white", textDecoration: "none" }} href="/data">
        <button className={styles.Button}>
          Learn How Nexfluent Creates Data Fluency
        </button>
      </a>
    </div>
  );
};

export default FlexCardContainer;
