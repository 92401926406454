import React from "react";
import styles from "./TopHubCard.module.css";
import HubImage from "../../Images/HubImage.png";
import HubImageMobile from "../../Images/HubImageMobile.png";

// import HubLogoImg from '../../Images/HubLogoImg.png'

const TopHubCard = () => {
  return (
    <div className={styles.topCard}>
      <div className={styles.TopImage}>
        <img
          src={window.innerWidth < 500 ? HubImageMobile : HubImage}
          alt="HubImage"
        />
        {/* <div className={styles.TopRight}>
          <img src={HubLogoImg} alt='HubLogoImg' />
        </div> */}
      </div>
    </div>
  );
};

export default TopHubCard;
