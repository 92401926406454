import React from "react";
import { Routes, Route } from "react-router-dom";
import Decision from "../DecisionPage/Decision.jsx";
import Home from "../HomePage/Home.jsx";
import Data from "../DataPage/Data.jsx";
import HubPage from "../HubPage/HubPage.jsx";

const AllRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/decision" element={<Decision />} />
      <Route path="/data" element={<Data />} />
      <Route path="/hub" element={<HubPage />} />
    </Routes>
  );
};

export default AllRoute;
