import React from "react";
import styles from "./NexFluentTransform.module.css";
import Transform1 from "../../Images/Transform1.svg";
import Transform2 from "../../Images/Transform2.svg";
import Line from "../../Images/Line.png";

const TopImage = () => {
  return (
    <div className={styles.topCard}>
      <div className={styles.FlextopCard}>
        <div className={styles.divH2}>
          <h2 className="font-size-subheader">
            <span className="font-size-heading">From Fragmented to Unified:</span>
            <br />
            Nexfluent Transforms Your Data Strategy
          </h2>
          {/* <h2>Nexfluent Transforms Your Data Strategy</h2> */}
        </div>
      </div>

      <div className={styles.FlexCard}>
        <div className={styles.flexItem}>
          <img src={Transform1} alt="" className={styles.flexImage} />
        </div>
        <div className={styles.flexItem}>
          <img src={Line} alt="" className={styles.flexImage} />
        </div>
        <div className={styles.flexItem}>
          <img src={Transform2} alt="" className={styles.flexImage} />
        </div>
      </div>
    </div>
  );
};

export default TopImage;
