import React from "react";
import styles from "./ForInvestor.module.css";
import smallArrow from "../../Images/smallArrow.svg";

const ForInvestor = () => {
  return (
    <div id="investor-section" className={styles.topCard}>
      <div className={styles.FlextopCard}>
        <div className={styles.divH2}>
          <h2 className="font-size-heading">
            <span>FOR INVESTORS</span>
          </h2>
          <h2
            className="font-size-heading"
            style={{ color: "#A4A4A4", fontWeight: 300 }}
          >
            The Billion Dollar Blind-spot: Fragmented Data Hinders Decision
            Intelligence.
          </h2>
          <h2 className="font-size-heading">Nexfluent is the Solution.</h2>
        </div>
      </div>

      <div className={styles.flexContainer}>
        <div className={styles.flexItem}>
          <div className={styles.centeredContainer1}>
            <p
              className="font-size-link"
              style={{
                textDecoration: "underline",
              }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Request Investor Relations Kit
              </a>
            </p>
            <img
              src={smallArrow}
              alt="smallArrow"
              className={styles.smallArrow}
            />
          </div>
        </div>

        <div className={styles.flexItem}>
          <div className={styles.centeredContainer1}>
            <p
              className="font-size-link"
              style={{
                display: "flex",
                textDecoration: "underline",
              }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Schedule a Discovery Call
              </a>
            </p>
            <img
              src={smallArrow}
              alt="smallArrow"
              className={styles.smallArrow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForInvestor;
