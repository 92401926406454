import React from "react";
import styles from "./MiddleCard.module.css";
import groupImage from "../../Images/groupImage.svg";
import Whynexfluent from "../../Images/Whynexfluent.svg";

const GridImage = () => {
  return (
    <div>
      <div className={styles.TopImage}>
        {window.innerWidth < 800 ? (
          <img src={Whynexfluent} alt="Whynexfluent" />
        ) : (
          <img src={groupImage} alt="groupImage" />
        )}
      </div>
    </div>
  );
};

export default GridImage;
