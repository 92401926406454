import React from "react";
import styles from "./WhyNexImg.module.css";
import NexFluentLogo from "../../Images/NexFluentLogo.svg";
// import VectorArrow from '../../Images/VectorArrow.svg'
import WhyNex from "../../Images/WhyNexImg.svg";

const WhyNexImg = () => {
  return (
    <div>
      <div className={styles.TopImage}>
        return (
        <div className={styles.container}>
          <div className={styles.content}>
            <h2 className="font-size-subheader why-nex-text">
              Why
              <img
                style={{ paddingLeft: "14px" }}
                src={NexFluentLogo}
                alt="NexFluentLogo"
                className="whynexfluent-img"
              />
            </h2>
            <p
              className="font-size-subheading"
              style={{
                color: "#c3c3c3",
              }}
            >
              Powered by state-of-the-art optimisation algorithms, companies can
              independently and fluently manage dynamic and complex problems.
            </p>
            <div className={styles.list}>
              <div className={styles.item}>
                <h4 className="font-size-link">
                  1. Weighing business scenarios
                </h4>
                {/* <img src={VectorArrow} alt='VectorArrow' /> */}
              </div>
              <div className={styles.item}>
                <h4 className="font-size-link">
                  2. Obtain predictions for individual business challenges
                </h4>
                {/* <img src={VectorArrow} alt='VectorArrow' /> */}
              </div>
              <div className={styles.item}>
                <h4 className="font-size-link">
                  3. Identify optimisation potential quickly and easily
                </h4>
                {/* <img src={VectorArrow} alt='VectorArrow' /> */}
              </div>
              <div className={styles.item}>
                <h4 className="font-size-link">
                  4. Breaking down data silos and utilising external data
                </h4>
                {/* <img src={VectorArrow} alt='VectorArrow' /> */}
              </div>
              <div className={styles.item}>
                <h4 className="font-size-link">
                  5. Less manual workload and operating costs
                </h4>
                {/* <img src={VectorArrow} alt='VectorArrow' /> */}
              </div>
            </div>
          </div>

          <div className={styles.Imagecontent}>
            <img src={WhyNex} alt="WhyNex" />
          </div>
        </div>
        );
      </div>
    </div>
  );
};

export default WhyNexImg;
