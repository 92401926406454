import React from 'react';
import styles from "./MiddleCard.module.css";

const MiddleCard = () => {
    return (
        <div className={`${styles.topCard}`}>
            <div className={styles.FlextopCard}>
                <div className={styles.divH3}>
                    <h2 className='font-size-heading'>Make all your decisions data-driven, across organisational units.</h2>
                </div>
                <div className={styles.divP}>
                    <p className='font-size-subheading'>We believe in the power of data. Our state-of-the-art AI decision-intelligence identifies untapped potential and helps your teams optimise decisions.</p>
                </div>
            </div>
        </div>
    )
}

export default MiddleCard
