import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import NexFluentLogo from '../Images/NexFluentLogo.svg';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    return (
        <div className={styles.FooterWrapper}>
            <div className={styles.FooterCard}>
                <div className={styles.LeftWrapper}>
                    <div className={styles.LeftContainer}>
                        <div className={styles.gridItem}><Link to="/data" className='font-size-navlink'>Data</Link></div>
                        <div className={styles.gridItem}><Link className='font-size-navlink' to="/decision">Decision</Link></div>
                        <div className={styles.gridItem}><Link className='font-size-navlink' to="/hub">Hub</Link></div>
                    </div>
                    {/* <div className={styles.LeftContainer}>
                        <div className={styles.gridItem}><Link className='font-size-navlink' to="/">Industries</Link></div>
                        <div className={styles.gridItem}><Link className='font-size-navlink' to="/">Investors</Link></div>
                    </div> */}
                </div>
                <div className={styles.RightContainer}>
                    <img src={NexFluentLogo} alt='NexFluentLogo' className={styles.Logo} />
                    <div className={styles.SocialIcons}>
{/*                         <SocialIcon url="https://www.linkedin.com/" /> */}
                        <SocialIcon url="mailto:info@nexfluent.com" />
                    </div>
                </div>
            </div>
            <div className={styles.Copyright}>
                <p>© 2024 NexFluent. All Rights Reserved.</p>
            </div>
        </div>
    );
};

export default Footer;
