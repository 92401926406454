import React from "react";
import styles from "./DataFuture.module.css";
import smallArrow from "../../Images/smallArrow.svg";

const DataFuture = () => {
  return (
    <div className={styles.topCardIndustries}>
      <div className={styles.FlextopCard}>
        <div className={styles.divH2}>
          <h2>
            <span
              className="font-size-heading"
              style={{
                lineHeight: "100%",
              }}
            >
              Take Control of Your Data Future :
            </span>
          </h2>
          <h2>
            <span
              className="font-size-subheader"
              style={{
                color: "#c3c3c3",
              }}
            >
              Ready to Unlock Your Data's Potential ?{" "}
            </span>
            <h2
            className="font-size-subheader"
              //   className={styles.secondH2Tag}
              style={{
                background: "linear-gradient(to right, #F56383, #0754EA)",
                WebkitBackgroundClip: "text",
                display:"inline",
                WebkitTextFillColor: "transparent",

                fontWeight: 400,
              }}
            >
              {" "}
              Get Started with Nexfluent
            </h2>
          </h2>
        </div>
      </div>

      <div className={styles.flexContainer}>
        <div className={styles.flexItem}>
          <div className={styles.centeredContainer1}>
            <p
              className="font-size-link"
              style={{
                textDecoration: "underline",
              }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Request a Free trial of the Nexfluent Data Hub
              </a>
            </p>
            <img
              src={smallArrow}
              alt="smallArrow"
              className={styles.smallArrow}
            />
          </div>
        </div>

        <div className={styles.flexItem}>
          <div className={styles.centeredContainer1}>
            <p
              className="font-size-link"
              style={{
                textDecoration: "underline",
              }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Schedule a demo showcasing Nexfluent's capabilities
              </a>
            </p>
            <img
              src={smallArrow}
              alt="smallArrow"
              className={styles.smallArrow}
            />
          </div>
        </div>
      </div>

      <div className={styles.flexContainer}>
        {/* <div className={styles.flexItem}>
                    <div className={styles.centeredContainer1}> */}
        {/* <p className={styles.paragraph}></p> */}
        {/* <img src={smallArrow} alt='smallArrow' className={styles.smallArrow} /> */}
        {/* </div>
                </div> */}

        <div className={styles.flexItem}>
          <div className={styles.centeredContainer1}>
            <p
              className="font-size-link"
              style={{
                textDecoration: "underline",
              }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Get on a Free consultation with data experts
              </a>
            </p>
            <img
              src={smallArrow}
              alt="smallArrow"
              className={styles.smallArrow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataFuture;
