import React from "react";
import styles from "./HomeTopCard.module.css";
import smallArrow from "../../Images/smallArrow.svg";
// import MainGif from "../../Images/MainGif.gif";
import "../../../main.css";
import { useNavigate } from "react-router-dom";
// import TopTextAndImg from './TopTextAndImg';

const HomeTopCard = () => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.topCard}`}>
      {/* <div className="data-page-section1">
        <img src={MainGif} alt="" />
        <div className="data-page-section1-text">
          <h1 className="font-size-subheader">
            <span className="font-size-heading">
              The Future of Data <br /> is Now:
            </span>
            <br />
            Nexfluent Revolutionizes Data Harmony & Decision Intelligence
          </h1>
          <p className="font-size-subheading">
            Embrace the power of AI-powered data unification and unlock a new
            era of data-driven success.
          </p>
        </div>
      </div> */}
      <div className="main-page-section1">
        {/* <div className="main-page-section1-gif">
          <img src={MainGif} alt="" />
        </div> */}
        <div className="main-section-text">
          <h1 className="font-size-heading">
            Data Centric AI is <span> NOW</span>
          </h1>
          <h3 className="font-size-subheader">
            Nexfluent Revolutionizes Data Harmony & Decision Intelligence
          </h3>
          <p className="font-size-subheading">
            Embrace the power of AI-powered data unification and unlock a new
            era of data-driven success.
          </p>
          <div className="main-page-links">
            <div
              className="main-link-item"
              onClick={() => {
                navigate("/data");
              }}
            >
              <img src={smallArrow} alt="" />
              <p>
                Optimize <span> Data Fluency</span>
              </p>
            </div>
            <div
              className="main-link-item"
              onClick={() => {
                navigate("/decision");
              }}
            >
              <img src={smallArrow} alt="" />
              <p>
                Enhance <span>Decision Fluency</span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTopCard;
