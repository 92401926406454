import React from 'react'
import TopCard from './TopCard/TopCard'
import MiddleCard from './MiddleCard/MiddleCard'
// import TopImage from './TopImage/TopImage'
import GridImage from './MiddleCard/GridImage'
import WhyNexImg from './WhyNex/WhyNexImg'
import LastText from './WhyNex/LastText'

const Home = () => {
  return (
    <div>
      <TopCard/>
      {/* <TopImage/> */}
      <MiddleCard/>
      <GridImage/>
      <WhyNexImg/>
      <LastText/>
    </div>
  )
}

export default Home
