import React from "react";
import styles from "./IndustryWeServe.module.css";
import smallArrow from "../../Images/smallArrow.svg";

const IndustryWeServe = () => {
  return (
    <div id="industries-section" className={styles.topCardIndustries}>
      <div className={styles.FlextopCard}>
        <div className={styles.divH2}>
          <h2 className="font-size-heading">Industries we serve</h2>
        </div>
      </div>

      <div className={styles.flexContainer}>
        <div className={styles.flexItem}>
          <div className={styles.flexItem1}>
            <h4
              className="font-size-subheading"
              style={{
                fontWeight: 300,
              }}
            >
              ENERGY
            </h4>
          </div>
          <div className={styles.flexItem2}>
            <h4 className="font-size-subheader">Oil & Gas</h4>
          </div>
          <div className={styles.flexItem2}>
            <h4 className="font-size-subheader">Renewables</h4>
          </div>
          <div className={styles.flexItem2}>
            <h4 className="font-size-subheader">Wind & Solar</h4>
          </div>

          <div className={styles.centeredContainer}>
            <p className={styles.paragraph}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Schedule a Demo
              </a>
            </p>
            <img
              src={smallArrow}
              alt="smallArrow"
              className={styles.smallArrow}
            />
          </div>
        </div>

        <div className={styles.flexItem}>
          <div className={styles.flexItem1}>
            <h4  className="font-size-subheading"
              style={{
                fontWeight: 300,
              }}>FINANCE</h4>
          </div>
          <div className={styles.flexItem2}>
            <h4 className="font-size-subheader">Banking</h4>
          </div>
          <div className={styles.flexItem2}>
            <h4 className="font-size-subheader">Insurance</h4>
          </div>
          <div className={styles.flexItem2}>
            <h4 className="font-size-subheader">Investments</h4>
          </div>

          <div className={styles.centeredContainer}>
            <p className={styles.paragraph}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Schedule a Demo
              </a>
            </p>
            <img
              src={smallArrow}
              alt="smallArrow"
              className={styles.smallArrow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryWeServe;
