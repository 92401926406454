import React from "react";
import styles from "./DecisionCount.module.css";
import Grid1 from "../../Images/Grid1.svg";
import Grid2 from "../../Images/Grid2.svg";
import Grid3 from "../../Images/Grid3.svg";
import Grid4 from "../../Images/Grid4.svg";
import Grid5 from "../../Images/Grid5.svg";
import Grid6 from "../../Images/Grid6.svg";
import smallArrow from "../../Images/smallArrow.svg";

const DecisionCount = () => {
  return (
    <div className={styles.topCard}>
      <div className={styles.FlextopCard}>
        <div className={styles.divH2}>
          <h2 className="font-size-heading" style={{
                color: "#c3c3c3",
                fontWeight:300
              }}>
            <span
              style={{
                color: "#fff",
                fontWeight:400
              }}
            >
              Make Every Decision Count:
            </span>
            <br />
            Nexfluent Empowers Confidence with Clear Data Insights.
          </h2>
          {/* <br /> */}
          {/* <h2>Nexfluent Empowers Confidence with Clear Data Insights.</h2> */}
        </div>
      </div>

      <div className={styles.gridContainer}>
        <div>
          <img src={Grid1} alt="Grid1" />
        </div>
        <div>
          <img src={Grid2} alt="Grid2" />
        </div>
        <div>
          <img src={Grid3} alt="Grid3" />
        </div>
        <div>
          <img src={Grid4} alt="Grid4" />
        </div>
        <div>
          <img src={Grid5} alt="Grid5" />
        </div>
        <div>
          <img src={Grid6} alt="Grid6" />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className={styles.centeredContainer}>
        <p className="font-size-link" style={{
            textDecoration:"underline"
        }}>
          <a style={{ color: "white", textDecoration: "none" }} href="/data">
            See Beyond the Data: Find out how Nexfluent Reveals the Stories
            Hidden Within.
          </a>
        </p>
        <img src={smallArrow} alt="smallArrow" className={styles.smallArrow} />
      </div>
      <br />
      <br />
    </div>
  );
};

export default DecisionCount;
