import React from "react";
import "./data.css";
// import HomeTopImage from "../Images/HomeTopImage.png";
import WaveImg from "../Images/WaveImg.png";

import TagCloud from "../Images/TagCloud.svg";
import Arrow from "../Images/arrow.svg";
const Data = () => {
  return (
    <>
      <div className="data-page-section1">
        <img src={WaveImg} alt="" />
        <div className="data-page-section1-text">
          <h1 className="font-size-subheader">
            <span className="font-size-heading">
              Orchestrate a Unified Data Vocabulary:
            </span>
            <br />
            Achieve Data Fluency with Nexfluent
          </h1>
          <p className="font-size-subheading">
            Build your organization's true data language and empower teams to
            speak it fluently
          </p>
          <a
            style={{ color: "white", textDecoration: "none" }}
            href="mailto:info@nexfluent.com"
          >
            <button>See how Nexfluent achieves this.</button>
          </a>
        </div>
      </div>
      <div className="tagcloud">
        <img src={TagCloud} alt="" />
        <p className="font-size-subheading">
          <span>Nexfluent</span> addresses a multi-billion dollar challenge:
          fragmented data hindering decision intelligence. Our AI-powered
          platform, built with state-of-the-art Generative AI and Machine
          Learning, fosters a unified data language across an organization. This
          unlocks hidden insights and empowers data fluency, driving seamless
          collaboration and superior decision-making at all levels. Nexfluent
          presents a significant investment opportunity to revolutionize data
          governance and unlock the true potential of data-driven decision
          intelligence across industries.
        </p>
      </div>
      <div className="data-section-3">
        <h1 className="font-size-heading">
          The Language of Success: <br />
          <span>Speak Data Fluently with Nexfluent</span>
        </h1>
        <div className="data-3-cards">
          <div className="data-3-card">
            <p className="font-size-subheading">
              Collaborative platform for creating and managing data definitions.
            </p>
          </div>
          <div className="data-3-card">
            <p className="font-size-subheading">
              Automated workflows for approval and version control.
            </p>
          </div>
          <div className="data-3-card">
            <p className="font-size-subheading">
              AI-powered suggestions to ensure consistency and accuracy.
            </p>
          </div>
          <div className="data-3-card">
            <p className="font-size-subheading">
              Integration tools to synchronise data definitions across
              platforms.
            </p>
          </div>
        </div>
      </div>
      <div className="data-section-5">
        <h1 className="font-size-heading">
          How Nexfluent Achieves Data fluency
        </h1>
        <div className="data-fluency-cards">
          <div className="data-fluency-card">
            <ul>
              <li className="font-size-navlink">
                Automatically discover and visually map all data sources,
                stores, flows, pipelines
              </li>
              <li className="font-size-navlink">
                Model relationships and connections between data domains
              </li>
              <li className="font-size-navlink">
                Assess metadata health, completeness, and quality scoring
              </li>
              <li className="font-size-navlink">
                Identify redundancies, overlaps, and optimization opportunities
              </li>
            </ul>
            <p className="font-size-subheading">Data Landscape Mapping</p>
          </div>
          <div className="data-fluency-card">
            <ul>
              <li>
                Govern and enforce data delivery, propagation, and access
                controls
              </li>
              <li>
                Data upstreaming, transformation, and refinement processes
              </li>
              <li>Proactive monitoring of data supply chain disruptions</li>
              <li>Track data journeys and multi-system lineage</li>
            </ul>
            <p className="font-size-subheading">Data Supply Chain Management</p>
          </div>
          <div className="data-fluency-card">
            <ul>
              <li>Catalog architectural schemas, structures, and models</li>
              <li>Manage and synchronize data persistence layers</li>
              <li>Validate architectural decisions against standards</li>
              <li>Holistic enterprise data architecture governance</li>
            </ul>
            <p className="font-size-subheading">Data Architecture Oversight</p>
          </div>
          <div className="data-fluency-card">
            <ul>
              <li>Codify and prep data policies, standards, and conventions</li>
              <li>
                Define and uphold data storage, hygiene, and disposable rules
              </li>
              <li>Data ethics and responsible AI guardrails</li>
              <li>Cultivate and measure data literacy and IQ</li>
            </ul>
            <p className="font-size-subheading">
              Data Civilization Administration
            </p>
          </div>
        </div>
      </div>
      <div className="data-section-4">
        <div className="">
          <h1 className="font-size-heading">

            A Common Ground for Data: <br />
            <span>
              How Nexfluent Benefits Every Player in Your Extended Business
              Network
            </span>
          </h1>
        </div>
        <p className="font-size-subheading">
          Nexfluent's unified data vocabulary ensures everyone within your
          organization's ecosystem speaks the same data language, fostering
          seamless collaboration and clear communication. Here's how it empowers
          different stakeholders:
        </p>

        <div className="common-cards">
          <div className="common-card">
            <p className="font-size-link">
              <span>Global Teams:</span> Eliminate confusion across borders and
              languages with a single source of truth for data definitions.
            </p>
          </div>
          <div className="common-card">
            <p className="font-size-link">
              <span>Press & Investors:</span> Communicate data-driven stories
              with confidence using clear, consistent metrics and terminology.
            </p>
          </div>
          <div className="common-card">
            <p className="font-size-link">
              <span>Regulators: </span>Simplify compliance processes with
              well-defined data points and transparent reporting.
            </p>
          </div>
          <div className="common-card">
            <p className="font-size-link">
              <span> Customers: </span>Gain a deeper understanding of your
              products and services through transparent data communication.
            </p>
          </div>
          <div className="common-card">
            <p className="font-size-link">
              <span>Procurement Teams:</span> Facilitate accurate comparisons
              and vendor selection through standardized data definitions.
            </p>
          </div>
          <div className="common-card">
            <p className="font-size-link">
              <span> Vendors & Contractors:</span> Ensure seamless integration
              and collaboration by aligning on data terminology.
            </p>
          </div>
        </div>
      </div>
      <div className="data-section-last">
        <h1 className="font-size-heading"> Start Building a Culture of Data Fluency Today</h1>
        <div className="links-comp">
          <div className="link">
            <p className="font-size-link">
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Request a Free trial of the Nexfluent Data Hub
              </a>{" "}
              <img src={Arrow} alt="" />{" "}
            </p>
          </div>
          <div className="link">
            <p className="font-size-link">
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Schedule a demo showcasing Nexfluent's capabilities
              </a>{" "}
              <img src={Arrow} alt="" />
            </p>
          </div>
          <div className="link">
            <p className="font-size-link">
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@nexfluent.com"
              >
                Get on a Free consultation with data experts
              </a>{" "}
              <img src={Arrow} alt="" />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Data;
