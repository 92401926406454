import React from "react";
// import styles from "./TopCard.module.css";
// import NexTopImage from "../../Images/NexTopImage.png";

const TopCard = () => {
  return (
    //         <div className={`${styles.topCard}`}>
    //             <div className={styles.FlextopCard}>
    //                 <div className={styles.emptyDiv}></div>
    //                 <div className={styles.divH2}>
    //                     <h2 className='font-size-heading'>The largest companies in the world make decisions differently.</h2>
    //                 </div>
    //             </div>

    //             <div className={styles.FlextopCard}>
    //                 <div className={styles.emptyDiv}></div>
    //                 <div className={styles.divP}>
    //                     <p className='font-size-subheading' style={{
    // opacity:0.6
    //                     }}>In a highly competitive & challenging global business landscape, decision fluency sets great companies apart from everyone else.</p>
    //                 </div>
    //             </div>
    //         </div>
    <div className="decision-page">
      <div className="decision-page-text">
        <h1 className="font-size-heading">
          The largest companies in the world make decisions differently.
        </h1>
        <p className="font-size-subheading">
          In a highly competitive & challenging global business landscape,
          decision fluency sets great companies apart from everyone else.
        </p>
      </div>
      <div className="decision-page-subtext">
        <p className="font-size-link">
          At Nexfluent, we transform the way businesses make decisions. Our
          AI-powered analysis tools turn your company's vast information nexus
          into fluent, actionable insights, enabling you to make smart,
          data-driven decisions.
        </p>
      </div>
    </div>
  );
};

export default TopCard;
