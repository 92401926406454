import React, { useState } from "react";
import "./Navbar.css";
import Logo from "../Images/NexFluentLogo.svg";
import Menu from "../Images/menu.svg";
import Close from "../Images/close.svg";

import { Link } from "react-scroll";

import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const NavLink = ({ children, href }) => {
    return (
      <Link
        to={href}
        smooth={true}
        offset={-50}
        duration={400}
        style={{ textDecoration: "none" }}
      >
        {children}
      </Link>
    );
  };

  return (
    <div className="nexfluent-nav">
      <div className="nav-logo">
        <img
          onClick={() => {
            navigate("/");
          }}
          src={Logo}
          alt=""
        />
      </div>
      <div className="links links1">
        <p
          onClick={() => {
            navigate("/data");
          }}
          href="/"
        >
          Data
        </p>
        <p
          onClick={() => {
            navigate("/decision");
          }}
        >
          Decisions
        </p>
        {/* <p
          onClick={() => {
            navigate("/hub");
          }}
        >
          Hub
        </p> */}
      </div>
      {window.location.pathname === "/" && (
        <div className="links links2">
          {/* <p>Industries</p> */}
          <NavLink href="industries-section" className="nav-link">
            <p>Industries</p>
          </NavLink>
          <NavLink href="investor-section" className="nav-link">
            <p>Investors</p>
          </NavLink>
        </div>
      )}
      <div className="menu" onClick={toggleMenu}>
        <img src={Menu} alt="" />
      </div>
      {isOpen && (
        <div className="menu-list">
          <div className="menu-items">
            <div
              onClick={() => {
                setIsOpen(false);
              }}
              className="close-icon"
            >
              <img src={Close} alt="" />
            </div>
            <p
              onClick={() => {
                navigate("/");
                setIsOpen(false);
              }}
            >
              Home
            </p>
            <p
              onClick={() => {
                navigate("/data");
                setIsOpen(false);
              }}
            >
              Data
            </p>
            <p
              onClick={() => {
                navigate("/decision");
                setIsOpen(false);
              }}
            >
              Decisions
            </p>
            {/* <p
              onClick={() => {
                navigate("/hub");
                setIsOpen(false);
              }}
            >
              Hub
            </p> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
