import './App.css';
import './global.css';
import './mobile.css';


import Footer from './Components/Footer/Footer.jsx';
import AllRoute from './Components/Navbar/AllRoutes.jsx';
import Navbar from './Components/Navbar/Navbar.jsx';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <AllRoute/>
      <Footer/>
    </div>
  );
}

export default App;
